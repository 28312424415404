// JsRoutes.generate is equivalent of `bundle exec rake js:routes`
/*
File generated by js-routes 1.4.4
Based on Rails routes of Iknow::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  function ParameterMissing(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber);
    if(Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  }

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf){
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_v2_templates => /templates/admin/:template(.:format)
  // function(template, options)
  admin_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// alexa_sessions_v2_templates => /templates/alexa_sessions/:template(.:format)
  // function(template, options)
  alexa_sessions_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"alexa_sessions",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assigned_goal_v2_series_learn => /series/:series_id/learn/assigned_goal(.:format)
  // function(series_id, options)
  assigned_goal_v2_series_learn_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"assigned_goal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// assigned_goal_v2_set_learn => /sets/:set_id/learn/assigned_goal(.:format)
  // function(set_id, options)
  assigned_goal_v2_set_learn_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"assigned_goal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// assignment_cards_v3_static_style_guide => /style-guide/assignment_cards(.:format)
  // function(options)
  assignment_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"assignment_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// assignments_v2_templates => /templates/assignments/:template(.:format)
  // function(template, options)
  assignments_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assignments_v3_learn_dashboard_templates => /v3/templates/learn/assignments/:template(.:format)
  // function(template, options)
  assignments_v3_learn_dashboard_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// boxes_v3_static_style_guide => /style-guide/boxes(.:format)
  // function(options)
  boxes_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"boxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// browsers_v2_templates => /templates/browsers/:template(.:format)
  // function(template, options)
  browsers_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"browsers",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_upload_v2_create_set => /create/sets/:id/bulk_upload(.:format)
  // function(id, options)
  bulk_upload_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bulk_upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// buttons_v3_static_style_guide => /style-guide/buttons(.:format)
  // function(options)
  buttons_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"buttons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// callback_v3_oauth => /oauth/:id/callback(.:format)
  // function(id, options)
  callback_v3_oauth_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_v3_plus_account => /plus/account/cancel(.:format)
  // function(options)
  cancel_v3_plus_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cards_v3_static_style_guide => /style-guide/cards(.:format)
  // function(options)
  cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checkboxes_v3_static_style_guide => /style-guide/checkboxes(.:format)
  // function(options)
  checkboxes_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"checkboxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cloning_laboratory_v2_admin_templates => /admin/templates/cloning_laboratory/:template(.:format)
  // function(template, options)
  cloning_laboratory_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"cloning_laboratory",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// collaborators_v2_create_set => /create/sets/:id/collaborators(.:format)
  // function(id, options)
  collaborators_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"collaborators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// common_v3_templates => /v3/templates/common/:template(.:format)
  // function(template, options)
  common_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"common",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// course_cards_v3_static_style_guide => /style-guide/course_cards(.:format)
  // function(options)
  course_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"course_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// courses_v3_learn_dashboard_templates => /v3/templates/learn/courses/:template(.:format)
  // function(template, options)
  courses_v3_learn_dashboard_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// courses_v3_templates => /v3/templates/courses/:template(.:format)
  // function(template, options)
  courses_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dropdowns_v3_static_style_guide => /style-guide/dropdowns(.:format)
  // function(options)
  dropdowns_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"dropdowns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_details_v2_create_series => /create/series/:id/edit_details(.:format)
  // function(id, options)
  edit_details_v2_create_series_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_details_v2_create_set => /create/sets/:id/edit_details(.:format)
  // function(id, options)
  edit_details_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_distractors_v2_create_set => /create/sets/:id/edit_distractors(.:format)
  // function(id, options)
  edit_distractors_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_distractors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_v3_create_set => /create/sets/:id/edit(.:format)
  // function(id, options)
  edit_v3_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_v3_create_set_item => /create/sets/:set_id/items/:id/edit(.:format)
  // function(set_id, id, options)
  edit_v3_create_set_item_path: Utils.route([["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_v3_partner => /partners/:id/edit(.:format)
  // function(id, options)
  edit_v3_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_v3_password => /passwords/:id/edit(.:format)
  // function(id, options)
  edit_v3_password_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_v3_plus_account => /plus/account/edit(.:format)
  // function(options)
  edit_v3_plus_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"plus",false],[2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_v4_edit_set => /edit/sets/:id/edit(.:format)
  // function(id, options)
  edit_v4_edit_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_v4_edit_set_item => /edit/sets/:set_id/items/:id/edit(.:format)
  // function(set_id, id, options)
  edit_v4_edit_set_item_path: Utils.route([["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// empty_states_v3_static_style_guide => /style-guide/empty_states(.:format)
  // function(options)
  empty_states_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"empty_states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// experiments_v2_admin_templates => /admin/templates/experiments/:template(.:format)
  // function(template, options)
  experiments_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"experiments",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// facebook_v3_oauth_index => /oauth/facebook(.:format)
  // function(options)
  facebook_v3_oauth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"facebook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// free_trials_v2_admin_templates => /admin/templates/free_trials/:template(.:format)
  // function(template, options)
  free_trials_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"free_trials",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// free_trials_v3_templates => /v3/templates/free_trials/:template(.:format)
  // function(template, options)
  free_trials_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"free_trials",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// google_v3_oauth_index => /oauth/google(.:format)
  // function(options)
  google_v3_oauth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"google",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// groups_v2_templates => /templates/groups/:template(.:format)
  // function(template, options)
  groups_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// groups_v3_templates => /v3/templates/groups/:template(.:format)
  // function(template, options)
  groups_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// icons_v3_static_style_guide => /style-guide/icons(.:format)
  // function(options)
  icons_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"icons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inputs_v3_static_style_guide => /style-guide/inputs(.:format)
  // function(options)
  inputs_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"inputs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_cards_v3_static_style_guide => /style-guide/item_cards(.:format)
  // function(options)
  item_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"item_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item_editor_v3_templates => /v3/templates/item_editor/:template(.:format)
  // function(template, options)
  item_editor_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"item_editor",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// item_editor_v4_templates => /v4/templates/item_editor/:template(.:format)
  // function(template, options)
  item_editor_v4_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v4",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"item_editor",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// item_type_cards_v3_static_style_guide => /style-guide/item_type_cards(.:format)
  // function(options)
  item_type_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"item_type_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// items_v3_create_sets_templates => /v3/templates/create/sets/items/:template(.:format)
  // function(template, options)
  items_v3_create_sets_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// items_v3_templates => /v3/templates/items/:template(.:format)
  // function(template, options)
  items_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// items_v4_templates => /v4/templates/items/:template(.:format)
  // function(template, options)
  items_v4_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v4",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// launcher_v2_admin_lti_index => /admin/lti/launcher(.:format)
  // function(options)
  launcher_v2_admin_lti_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[6,"launcher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// learn_dashboard_v3_templates => /v3/templates/learn_dashboard/:template(.:format)
  // function(template, options)
  learn_dashboard_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"learn_dashboard",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// learn_v2_templates => /templates/learn/:template(.:format)
  // function(template, options)
  learn_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// learn_v3_templates => /v3/templates/learn/:template(.:format)
  // function(template, options)
  learn_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lti_inactivity_logout_v3_static => /lti_inactivity_logout(.:format)
  // function(options)
  lti_inactivity_logout_v3_static_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_inactivity_logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// lti_users_v3_templates => /v3/templates/lti_users/:template(.:format)
  // function(template, options)
  lti_users_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"lti_users",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// lti_v2_admin_templates => /admin/templates/lti/:template(.:format)
  // function(template, options)
  lti_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"lti",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// memory_bank_v2_create_series => /create/series/:id/memory_bank(.:format)
  // function(id, options)
  memory_bank_v2_create_series_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"memory_bank",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// memory_bank_v2_create_set => /create/sets/:id/memory_bank(.:format)
  // function(id, options)
  memory_bank_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"memory_bank",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// memory_bank_v2_series => /series/:id/memory_bank(.:format)
  // function(id, options)
  memory_bank_v2_series_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"memory_bank",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// memory_bank_v2_set => /sets/:id/memory_bank(.:format)
  // function(id, options)
  memory_bank_v2_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"memory_bank",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// memory_bank_v2_templates => /templates/memory_bank/:template(.:format)
  // function(template, options)
  memory_bank_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"memory_bank",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// message_cards_v3_static_style_guide => /style-guide/message_cards(.:format)
  // function(options)
  message_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"message_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// modals_v3_static_style_guide => /style-guide/modals(.:format)
  // function(options)
  modals_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"modals",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_v2_admin_experiment => /admin/experiments/new(.:format)
  // function(options)
  new_v2_admin_experiment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"experiments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_v2_admin_survey => /admin/survey/new(.:format)
  // function(options)
  new_v2_admin_survey_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"survey",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_v2_alexa_session => /alexa_sessions/new(.:format)
  // function(options)
  new_v2_alexa_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alexa_sessions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_v2_series => /series/new(.:format)
  // function(options)
  new_v2_series_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_v2_set => /sets/new(.:format)
  // function(options)
  new_v2_set_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_v3_create_set_item => /create/sets/:set_id/items/new(.:format)
  // function(set_id, options)
  new_v3_create_set_item_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_v3_password => /passwords/new(.:format)
  // function(options)
  new_v3_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"passwords",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_v4_edit_set_item => /edit/sets/:set_id/items/new(.:format)
  // function(set_id, options)
  new_v4_edit_set_item_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// org_dash_cards_v3_static_style_guide => /style-guide/org_dash_cards(.:format)
  // function(options)
  org_dash_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"org_dash_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pagination_v3_static_style_guide => /style-guide/pagination(.:format)
  // function(options)
  pagination_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"pagination",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partners_v2_admin_templates => /admin/templates/partners/:template(.:format)
  // function(template, options)
  partners_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partners_v2_templates => /templates/partners/:template(.:format)
  // function(template, options)
  partners_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partners_v3_templates => /v3/templates/partners/:template(.:format)
  // function(template, options)
  partners_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// paste_csv_v2_create_set => /create/sets/:id/paste_csv(.:format)
  // function(id, options)
  paste_csv_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"paste_csv",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// primary_learn_cta_v3_static_style_guide => /style-guide/primary_learn_cta(.:format)
  // function(options)
  primary_learn_cta_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"primary_learn_cta",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pro_v2_upgrade_templates => /upgrade/templates/pro/:template(.:format)
  // function(template, options)
  pro_v2_upgrade_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"upgrade",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"pro",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// quick_entry_v2_create_set => /create/sets/:id/quick_entry(.:format)
  // function(id, options)
  quick_entry_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"quick_entry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// quiz_v2_learn => /learn/quiz(.:format)
  // function(options)
  quiz_v2_learn_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"quiz",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// quiz_v2_series_learn => /series/:series_id/learn/quiz(.:format)
  // function(series_id, options)
  quiz_v2_series_learn_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"quiz",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// quiz_v2_set_learn => /sets/:set_id/learn/quiz(.:format)
  // function(set_id, options)
  quiz_v2_set_learn_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"quiz",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// regions_v2_templates => /templates/regions/:template(.:format)
  // function(template, options)
  regions_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// registrations_v2_templates => /templates/registrations/:template(.:format)
  // function(template, options)
  registrations_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// registrations_v3_templates => /v3/templates/registrations/:template(.:format)
  // function(template, options)
  registrations_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reports_v3_courses_templates => /v3/courses/templates/reports/:template(.:format)
  // function(template, options)
  reports_v3_courses_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// revision_history_v2_create_set => /create/sets/:id/revision_history(.:format)
  // function(id, options)
  revision_history_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revision_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// series_v2_create_set => /create/sets/:id/series(.:format)
  // function(id, options)
  series_v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"series",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// series_v2_set => /sets/:id/series(.:format)
  // function(id, options)
  series_v2_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"series",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// series_v2_templates => /templates/series/:template(.:format)
  // function(template, options)
  series_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sessions_v2_templates => /templates/sessions/:template(.:format)
  // function(template, options)
  sessions_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"sessions",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sets_v2_admin_templates => /admin/templates/sets/:template(.:format)
  // function(template, options)
  sets_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sets_v2_templates => /templates/sets/:template(.:format)
  // function(template, options)
  sets_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sets_v3_create_templates => /v3/templates/create/sets/:template(.:format)
  // function(template, options)
  sets_v3_create_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// signup_v2_partner => /partners/:id/signup(.:format)
  // function(id, options)
  signup_v2_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// situation_finder_v2_admin_templates => /admin/templates/situation_finder/:template(.:format)
  // function(template, options)
  situation_finder_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"situation_finder",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// snackbars_v3_static_style_guide => /style-guide/snackbars(.:format)
  // function(options)
  snackbars_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"snackbars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// static_v2_templates => /templates/static/:template(.:format)
  // function(template, options)
  static_v2_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"static",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// static_v3_templates => /v3/templates/static/:template(.:format)
  // function(template, options)
  static_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"static",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// status_cards_v3_static_style_guide => /style-guide/status_cards(.:format)
  // function(options)
  status_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"status_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// style_guide_v3_static_templates => /v3/templates/static/style_guide/:template(.:format)
  // function(template, options)
  style_guide_v3_static_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"static",false],[2,[7,"/",false],[2,[6,"style_guide",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// summary_screen_v3_static_style_guide => /style-guide/summary_screen(.:format)
  // function(options)
  summary_screen_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"summary_screen",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// survey_v2_admin_templates => /admin/templates/survey/:template(.:format)
  // function(template, options)
  survey_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"survey",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tables_v3_static_style_guide => /style-guide/tables(.:format)
  // function(options)
  tables_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"tables",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tabs_v3_static_style_guide => /style-guide/tabs(.:format)
  // function(options)
  tabs_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"tabs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tis_boxes_v3_static_style_guide => /style-guide/tis_boxes(.:format)
  // function(options)
  tis_boxes_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"tis_boxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tis_v3_static_style_guide => /style-guide/tis(.:format)
  // function(options)
  tis_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"tis",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// toast_v3_templates => /v3/templates/toast/:template(.:format)
  // function(template, options)
  toast_v3_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"toast",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toggles_v3_static_style_guide => /style-guide/toggles(.:format)
  // function(options)
  toggles_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"toggles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tooltips_v3_static_style_guide => /style-guide/tooltips(.:format)
  // function(options)
  tooltips_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"tooltips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// twitter_v3_oauth_index => /oauth/twitter(.:format)
  // function(options)
  twitter_v3_oauth_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"twitter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// typography_v3_static_style_guide => /style-guide/typography(.:format)
  // function(options)
  typography_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"typography",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unstarted_v3_partner_dashboard => /partners/:partner_id/dashboard/unstarted(.:format)
  // function(partner_id, options)
  unstarted_v3_partner_dashboard_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"unstarted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upgrade_v2_browser => /browser/upgrade(.:format)
  // function(options)
  upgrade_v2_browser_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"browser",false],[2,[7,"/",false],[2,[6,"upgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// uploader_cards_v3_static_style_guide => /style-guide/uploader_cards(.:format)
  // function(options)
  uploader_cards_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"uploader_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// uploads_v3_static_style_guide => /style-guide/uploads(.:format)
  // function(options)
  uploads_v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[2,[7,"/",false],[2,[6,"uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_v2_admin_templates => /admin/templates/users/:template(.:format)
  // function(template, options)
  users_v2_admin_templates_path: Utils.route([["template",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v2 => /sets/:set_id/items/new(.:format)
  // function(set_id, options)
  v2_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v2_EALResources => /EALResources(.:format)
  // function(options)
  v2_EALResources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"EALResources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_SummerLearningChallenge => /SummerLearningChallenge(.:format)
  // function(options)
  v2_SummerLearningChallenge_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"SummerLearningChallenge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_about_plus => /about-plus(.:format)
  // function(options)
  v2_about_plus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"about-plus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_admin => /admin(.:format)
  // function(options)
  v2_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_admin_cloning_laboratory => /admin/cloning_laboratory(.:format)
  // function(options)
  v2_admin_cloning_laboratory_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cloning_laboratory",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_experiment => /admin/experiments/:id(.:format)
  // function(id, options)
  v2_admin_experiment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"experiments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_admin_experiments => /admin/experiments(.:format)
  // function(options)
  v2_admin_experiments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"experiments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_free_trials => /admin/free_trials(.:format)
  // function(options)
  v2_admin_free_trials_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"free_trials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_partner => /admin/partners/:id(.:format)
  // function(id, options)
  v2_admin_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_admin_partners => /admin/partners(.:format)
  // function(options)
  v2_admin_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_set => /admin/sets/:id(.:format)
  // function(id, options)
  v2_admin_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_admin_situation_finder => /admin/situation_finder(.:format)
  // function(options)
  v2_admin_situation_finder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"situation_finder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_survey => /admin/survey/:id(.:format)
  // function(id, options)
  v2_admin_survey_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"survey",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_admin_survey_index => /admin/survey(.:format)
  // function(options)
  v2_admin_survey_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"survey",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_admin_user => /admin/users/:id(.:format)
  // function(id, options)
  v2_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_admin_users => /admin/users(.:format)
  // function(options)
  v2_admin_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_advisors => /advisors(.:format)
  // function(options)
  v2_advisors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"advisors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_alexa_sessions => /alexa_sessions(.:format)
  // function(options)
  v2_alexa_sessions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alexa_sessions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_assignments => /assignments(.:format)
  // function(options)
  v2_assignments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_blog => /blog(.:format)
  // function(options)
  v2_blog_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_brand_guidelines => /brand-guidelines(.:format)
  // function(options)
  v2_brand_guidelines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"brand-guidelines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_commercial => /commercial(.:format)
  // function(options)
  v2_commercial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"commercial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_company => /company(.:format)
  // function(options)
  v2_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_company_jobs => /company/jobs(.:format)
  // function(options)
  v2_company_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_company_team => /company/team(.:format)
  // function(options)
  v2_company_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"team",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_contact => /contact(.:format)
  // function(options)
  v2_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_corporate => /corporate(.:format)
  // function(options)
  v2_corporate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"corporate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_courses_guide => /courses-guide(.:format)
  // function(options)
  v2_courses_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses-guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_courseware => /courseware(.:format)
  // function(options)
  v2_courseware_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courseware",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_create_series => /create/series/:id(.:format)
  // function(id, options)
  v2_create_series_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_create_set => /create/sets/:id(.:format)
  // function(id, options)
  v2_create_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_documentation => /documentation(.:format)
  // function(options)
  v2_documentation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"documentation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_edx => /edx(.:format)
  // function(options)
  v2_edx_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"edx",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_eula => /eula(.:format)
  // function(options)
  v2_eula_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"eula",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_facebook => /facebook(.:format)
  // function(options)
  v2_facebook_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"facebook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_freetrial => /freetrial(.:format)
  // function(options)
  v2_freetrial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"freetrial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_getexcited => /getexcited(.:format)
  // function(options)
  v2_getexcited_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"getexcited",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_gps_analytics => /gps/analytics(.:format)
  // function(options)
  v2_gps_analytics_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gps",false],[2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_gps_content_creation => /gps/content-creation(.:format)
  // function(options)
  v2_gps_content_creation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gps",false],[2,[7,"/",false],[2,[6,"content-creation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_gps_getting_started => /gps/getting-started(.:format)
  // function(options)
  v2_gps_getting_started_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gps",false],[2,[7,"/",false],[2,[6,"getting-started",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups => /groups(.:format)
  // function(options)
  v2_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_groups_about => /groups/about(.:format)
  // function(options)
  v2_groups_about_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"about",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups_dashboard => /groups/dashboard(.:format)
  // function(options)
  v2_groups_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups_home => /groups/home(.:format)
  // function(options)
  v2_groups_home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups_manage => /groups/manage(.:format)
  // function(options)
  v2_groups_manage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"manage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups_signup => /groups/signup(.:format)
  // function(options)
  v2_groups_signup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_groups_subscription_cancel => /groups/subscription/cancel(.:format)
  // function(options)
  v2_groups_subscription_cancel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_groups_subscription_edit => /groups/subscription/edit(.:format)
  // function(options)
  v2_groups_subscription_edit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_groups_subscription_new => /groups/subscription/new(.:format)
  // function(options)
  v2_groups_subscription_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_home => /home(.:format)
  // function(options)
  v2_home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_how_it_works => /how_it_works(.:format)
  // function(options)
  v2_how_it_works_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"how_it_works",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_improve_product => /improve-product(.:format)
  // function(options)
  v2_improve_product_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"improve-product",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_jobs => /jobs(.:format)
  // function(options)
  v2_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_learn => /learn(.:format)
  // function(options)
  v2_learn_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_learning => /learning(.:format)
  // function(options)
  v2_learning_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_linkedin => /linkedin(.:format)
  // function(options)
  v2_linkedin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"linkedin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_memory_bank => /memory_bank(.:format)
  // function(options)
  v2_memory_bank_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"memory_bank",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_mission => /mission(.:format)
  // function(options)
  v2_mission_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_my_library => /my_library(.:format)
  // function(options)
  v2_my_library_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"my_library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_newsroom => /newsroom(.:format)
  // function(options)
  v2_newsroom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"newsroom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_partner_notifications => /partners/:partner_id/notifications(.:format)
  // function(partner_id, options)
  v2_partner_notifications_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_partner_sets => /partners/:partner_id/sets(.:format)
  // function(partner_id, options)
  v2_partner_sets_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_partner_stories => /partner-stories(.:format)
  // function(options)
  v2_partner_stories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner-stories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_partner_unsubscribe => /partner/:partner_id/unsubscribe/:token(.:format)
  // function(partner_id, token, options)
  v2_partner_unsubscribe_path: Utils.route([["partner_id",true],["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"unsubscribe",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v2_partners => /partners(.:format)
  // function(options)
  v2_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_press => /press(.:format)
  // function(options)
  v2_press_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"press",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_pricing => /pricing(.:format)
  // function(options)
  v2_pricing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pricing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_privacy => /privacy(.:format)
  // function(options)
  v2_privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_privacy_policy => /privacy_policy(.:format)
  // function(options)
  v2_privacy_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy_policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_products => /products(.:format)
  // function(options)
  v2_products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"products",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_resources => /resources(.:format)
  // function(options)
  v2_resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_series_learn => /series/:series_id/learn(.:format)
  // function(series_id, options)
  v2_series_learn_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"learn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_set_learn => /sets/:set_id/learn(.:format)
  // function(set_id, options)
  v2_set_learn_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"learn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v2_sets => /sets(.:format)
  // function(options)
  v2_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_solutions => /solutions(.:format)
  // function(options)
  v2_solutions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"solutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_solutions_business => /solutions/business(.:format)
  // function(options)
  v2_solutions_business_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"solutions",false],[2,[7,"/",false],[2,[6,"business",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_solutions_higher_education => /solutions/higher-education(.:format)
  // function(options)
  v2_solutions_higher_education_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"solutions",false],[2,[7,"/",false],[2,[6,"higher-education",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_team => /team(.:format)
  // function(options)
  v2_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"team",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_technology => /technology(.:format)
  // function(options)
  v2_technology_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"technology",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_template => /templates/:id(.:format)
  // function(id, options)
  v2_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_terms_of_service => /terms_of_service(.:format)
  // function(options)
  v2_terms_of_service_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"terms_of_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_train_people => /train-people(.:format)
  // function(options)
  v2_train_people_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"train-people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_twitter => /twitter(.:format)
  // function(options)
  v2_twitter_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"twitter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v2_unsubscribe => /unsubscribe/:token(.:format)
  // function(token, options)
  v2_unsubscribe_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"unsubscribe",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_upgrade_pro => /upgrade/pro(.:format)
  // function(options)
  v2_upgrade_pro_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"upgrade",false],[2,[7,"/",false],[2,[6,"pro",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v2_whitepaper => /whitepaper(.:format)
  // function(options)
  v2_whitepaper_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"whitepaper",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_add_contributor => /add_contributor(.:format)
  // function(options)
  v3_add_contributor_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"add_contributor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_course => /courses/:id(.:format)
  // function(id, options)
  v3_course_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_course_analytics => /courses/:course_id/analytics(.:format)
  // function(course_id, options)
  v3_course_analytics_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"analytics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_assignment => /courses/:course_id/assignments/:id(.:format)
  // function(course_id, id, options)
  v3_course_assignment_path: Utils.route([["course_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"assignments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_course_assignments => /courses/:course_id/assignments(.:format)
  // function(course_id, options)
  v3_course_assignments_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_instructors => /courses/:course_id/instructors(.:format)
  // function(course_id, options)
  v3_course_instructors_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"instructors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_invite => /courses/signup(.:format)
  // function(options)
  v3_course_invite_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_course_learners => /courses/:course_id/learners(.:format)
  // function(course_id, options)
  v3_course_learners_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"learners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_reports => /courses/:course_id/reports(.:format)
  // function(course_id, options)
  v3_course_reports_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_settings => /courses/:course_id/settings(.:format)
  // function(course_id, options)
  v3_course_settings_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_course_user => /courses/:course_id/users/:id(.:format)
  // function(course_id, id, options)
  v3_course_user_path: Utils.route([["course_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_courses => /courses(.:format)
  // function(options)
  v3_courses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_create_course_series => /create/courses/:course_id/series/:id(.:format)
  // function(course_id, id, options)
  v3_create_course_series_path: Utils.route([["course_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_create_course_set => /create/courses/:course_id/sets/:id(.:format)
  // function(course_id, id, options)
  v3_create_course_set_path: Utils.route([["course_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_create_set_item_notes => /create/sets/:set_id/items/:item_id/notes(.:format)
  // function(set_id, item_id, options)
  v3_create_set_item_notes_path: Utils.route([["set_id",true],["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v3_create_set_item_options => /create/sets/:set_id/items/:item_id/options(.:format)
  // function(set_id, item_id, options)
  v3_create_set_item_options_path: Utils.route([["set_id",true],["item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"item_id",false],[2,[7,"/",false],[2,[6,"options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// v3_dashboard => /dashboard(.:format)
  // function(options)
  v3_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_email_confirmation => /email_confirmations/:id(.:format)
  // function(id, options)
  v3_email_confirmation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"email_confirmations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_free_trial => /free-trial(.:format)
  // function(options)
  v3_free_trial_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"free-trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_group_user => /groups/:group_id/users/:id(.:format)
  // function(group_id, id, options)
  v3_group_user_path: Utils.route([["group_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_join_course => /join/:partner_id/:course_id(.:format)
  // function(partner_id, course_id, options)
  v3_join_course_path: Utils.route([["partner_id",true],["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"join",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[3,"course_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_learn_course => /learn/courses/:id(.:format)
  // function(id, options)
  v3_learn_course_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_learn_course_instructional_item => /learn/courses/:course_id/instructional_items/:id(.:format)
  // function(course_id, id, options)
  v3_learn_course_instructional_item_path: Utils.route([["course_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_learn_course_instructional_items => /learn/courses/:course_id/instructional_items(.:format)
  // function(course_id, options)
  v3_learn_course_instructional_items_path: Utils.route([["course_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_instructional_item => /learn/instructional_items/:id(.:format)
  // function(id, options)
  v3_learn_instructional_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_learn_series => /learn/series/:id(.:format)
  // function(id, options)
  v3_learn_series_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_learn_series_advanced_index => /learn/series/:series_id/advanced(.:format)
  // function(series_id, options)
  v3_learn_series_advanced_index_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"advanced",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_series_assignments => /learn/series/:series_id/assignments(.:format)
  // function(series_id, options)
  v3_learn_series_assignments_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"assignments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_series_instructional_item => /learn/series/:series_id/instructional_items/:id(.:format)
  // function(series_id, id, options)
  v3_learn_series_instructional_item_path: Utils.route([["series_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_learn_series_instructional_items => /learn/series/:series_id/instructional_items(.:format)
  // function(series_id, options)
  v3_learn_series_instructional_items_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_series_memories => /learn/series/:series_id/memories(.:format)
  // function(series_id, options)
  v3_learn_series_memories_path: Utils.route([["series_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"series",false],[2,[7,"/",false],[2,[3,"series_id",false],[2,[7,"/",false],[2,[6,"memories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_set => /learn/sets/:id(.:format)
  // function(id, options)
  v3_learn_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_learn_set_advanced_index => /learn/sets/:set_id/advanced(.:format)
  // function(set_id, options)
  v3_learn_set_advanced_index_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"advanced",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_set_instructional_item => /learn/sets/:set_id/instructional_items/:id(.:format)
  // function(set_id, id, options)
  v3_learn_set_instructional_item_path: Utils.route([["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_learn_set_instructional_items => /learn/sets/:set_id/instructional_items(.:format)
  // function(set_id, options)
  v3_learn_set_instructional_items_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"instructional_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_learn_set_memories => /learn/sets/:set_id/memories(.:format)
  // function(set_id, options)
  v3_learn_set_memories_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"memories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_library => /library(.:format)
  // function(options)
  v3_library_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_lti_redirect_courses => /lti_redirect/courses(.:format)
  // function(options)
  v3_lti_redirect_courses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_redirect",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_lti_redirect_create => /lti_redirect/create(.:format)
  // function(options)
  v3_lti_redirect_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_redirect",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_lti_redirect_learn => /lti_redirect/learn(.:format)
  // function(options)
  v3_lti_redirect_learn_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_redirect",false],[2,[7,"/",false],[2,[6,"learn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_lti_redirect_learn_dashboard => /lti_redirect/learn_dashboard(.:format)
  // function(options)
  v3_lti_redirect_learn_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_redirect",false],[2,[7,"/",false],[2,[6,"learn_dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_lti_users_confirm => /lti_users/confirm(.:format)
  // function(options)
  v3_lti_users_confirm_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lti_users",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_merge_request => /merge_requests/:id(.:format)
  // function(id, options)
  v3_merge_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"merge_requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_native_app_launcher => /native_app_launcher(.:format)
  // function(options)
  v3_native_app_launcher_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"native_app_launcher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_partner_api_users => /partners/:partner_id/api_users(.:format)
  // function(partner_id, options)
  v3_partner_api_users_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"api_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_app_branding => /partners/:partner_id/app_branding(.:format)
  // function(partner_id, options)
  v3_partner_app_branding_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"app_branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_dashboard => /partners/:partner_id/dashboard(.:format)
  // function(partner_id, options)
  v3_partner_dashboard_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_departments => /partners/:partner_id/departments(.:format)
  // function(partner_id, options)
  v3_partner_departments_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"departments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_features => /partners/:partner_id/features(.:format)
  // function(partner_id, options)
  v3_partner_features_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"features",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_group => /partners/:partner_id/groups/:id(.:format)
  // function(partner_id, id, options)
  v3_partner_group_path: Utils.route([["partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_partner_group_courses => /partners/:partner_id/groups/:group_id/courses(.:format)
  // function(partner_id, group_id, options)
  v3_partner_group_courses_path: Utils.route([["partner_id",true],["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_partner_group_members => /partners/:partner_id/groups/:group_id/members(.:format)
  // function(partner_id, group_id, options)
  v3_partner_group_members_path: Utils.route([["partner_id",true],["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_partner_group_reports => /partners/:partner_id/groups/:group_id/reports(.:format)
  // function(partner_id, group_id, options)
  v3_partner_group_reports_path: Utils.route([["partner_id",true],["group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"groups",false],[2,[7,"/",false],[2,[3,"group_id",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// v3_partner_groups => /partners/:partner_id/groups(.:format)
  // function(partner_id, options)
  v3_partner_groups_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_learning_library => /partners/:partner_id/learning_library(.:format)
  // function(partner_id, options)
  v3_partner_learning_library_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"learning_library",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_oauth => /partners/:partner_id/oauth(.:format)
  // function(partner_id, options)
  v3_partner_oauth_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"oauth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_people => /partners/:partner_id/people(.:format)
  // function(partner_id, options)
  v3_partner_people_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"people",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_staff => /partners/:partner_id/staff(.:format)
  // function(partner_id, options)
  v3_partner_staff_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_partner_user => /partners/:partner_id/users/:id(.:format)
  // function(partner_id, id, options)
  v3_partner_user_path: Utils.route([["partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_partners_registrations_new => /partners/registrations/new(.:format)
  // function(options)
  v3_partners_registrations_new_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"registrations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v3_react_course_assignment => /app/courses/:course_id/sets/:set_id
  // function(course_id, set_id, options)
  v3_react_course_assignment_path: Utils.route([["course_id",true],["set_id",true]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[3,"set_id",false]]]]]]]]]]),
// v3_react_learn_set => /app/learn/sets/:set_id
  // function(set_id, options)
  v3_react_learn_set_path: Utils.route([["set_id",true]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[3,"set_id",false]]]]]]]]),
// v3_react_user_journey => /app/sets/:set_id/journey
  // function(set_id, options)
  v3_react_user_journey_path: Utils.route([["set_id",true]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[6,"journey",false]]]]]]]]),
// v3_root => /
  // function(options)
  v3_root_path: Utils.route([], {}, [7,"/",false]),
// v3_scorm_upload => /v3/scorm_upload(.:format)
  // function(options)
  v3_scorm_upload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"scorm_upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// v3_set_learn => /v3/sets/:set_id/learn(.:format)
  // function(set_id, options)
  v3_set_learn_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"learn",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v3_signin => /signin(.:format)
  // function(options)
  v3_signin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"signin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_signout => /signout(.:format)
  // function(options)
  v3_signout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"signout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_static_style_guide => /style-guide(.:format)
  // function(options)
  v3_static_style_guide_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"style-guide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v3_temp_login => /temp_login(.:format)
  // function(options)
  v3_temp_login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"temp_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v4_react_dashboard => /app/nav/dashboard
  // function(options)
  v4_react_dashboard_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"nav",false],[2,[7,"/",false],[6,"dashboard",false]]]]]]),
// workload_v2_static => /workload(.:format)
  // function(options)
  workload_v2_static_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"workload",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("iKnowRoutes" + ".defaults is removed. Use " + "iKnowRoutes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "iKnowRoutes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);


// no export needed, because JsRoutes.generate produces JS that will attach itself to 'this'
// which webpack will put in the right place according to the config.output.library value

// After everything is moved to webpack we can add this line for explicitness, but if we have it now,
// the sprockets global reference will be Packs.iKnowRoutes.iKnowRoutes
// export default this.iKnowRoutes;

// Based on https://stackoverflow.com/questions/45043674/webpacker-including-javascript-from-a-gem-js-routes
